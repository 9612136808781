exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-about-js": () => import("./../../../src/pages/page/about.js" /* webpackChunkName: "component---src-pages-page-about-js" */),
  "component---src-pages-page-imprint-js": () => import("./../../../src/pages/page/imprint.js" /* webpackChunkName: "component---src-pages-page-imprint-js" */),
  "component---src-pages-page-projects-js": () => import("./../../../src/pages/page/projects.js" /* webpackChunkName: "component---src-pages-page-projects-js" */),
  "component---src-pages-page-request-form-js": () => import("./../../../src/pages/page/request-form.js" /* webpackChunkName: "component---src-pages-page-request-form-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

